import React from "react";

const Footer = () => {
    return (
        <footer>
            <div className="wrapper">
                <div className="info">© 2023 All rights reserved – Flotferðir ehf Reg. Nr. 631014-0890 VAT nr 122908</div>
                <ul>
                    <li><a href="https://www.buubble.com/" target="_blank" rel="noreferrer"><i className="fa-solid fa-arrow-up-right-from-square"></i> Buubble.com </a></li>
                    <li><a href="https://www.facebook.com/bubblesiceland/" target="_blank" rel="noreferrer"><i className="fa-brands fa-square-facebook"></i></a></li>
                    <li><a href="https://www.instagram.com/bubbleiceland" target="_blank" rel="noreferrer"><i className="fa-brands fa-instagram"></i></a></li>
                    <li><a href="https://twitter.com/bubbleiceland" target="_blank" rel="noreferrer"><i className="fa-brands fa-x-twitter"></i></a></li>
                </ul>
            </div>
        </footer>
    );
};

export default Footer;