import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {Helmet} from "react-helmet";
import './App.css';
import Navbar from './components/Navbar';
import Trips from './pages/Trips';
import SelfDrive from './pages/SelfDrive';
import ThankYou from './pages/thank-you-for-booking';
import Trip from './pages/Trip';
import Footer from './components/Footer';
import TagManager from 'react-gtm-module'
/*
const tagManagerArgs = {
    gtmId: 'GTM-TBPXHJL'
}

TagManager.initialize(tagManagerArgs)
*/
function App() {

  return (
    <div className="App">
      <Helmet>
          <meta charSet="utf-8" />
          <title>Tours at buubble.com</title>
          <link rel="canonical" href="https://tours.buubble.com/" />
          <script src="https://kit.fontawesome.com/bf1969079f.js" crossorigin="anonymous"></script>
          <script async data-id="100865338" src="//static.getclicky.com/js"></script>
          <script id="facebook-pixel-script">
            {
                `!function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '726172684096484');
                fbq('track', 'PageView');`
            }</script>
            <noscript id="facebook-pixel-image">
            {
                `<img height="1" width="1" style="display:none"
                src="https://www.facebook.com/tr?id=726172684096484&ev=PageView&noscript=1"
                />`
            }</noscript>
            <script id="pipedrive-chat">
            {
                `window.pipedriveLeadboosterConfig = {base: 'leadbooster-chat.pipedrive.com',companyId: 11430630,playbookUuid: '4b27d69b-3480-4749-9502-4e6eb105beeb',version: 2};(function () {var w = window;if (w.LeadBooster) {console.warn('LeadBooster already exists');} else {w.LeadBooster = {q: [],on: function (n, h) {this.q.push({ t: 'o', n: n, h: h });},trigger: function (n) {this.q.push({ t: 't', n: n });},};}})();`
            }</script>
            <script async src="https://www.googletagmanager.com/gtag/js?id=GTM-TBPXHJL"></script>
            <script>{
                `window.dataLayer = window.dataLayer || [];
                function gtag() {
                    dataLayer.push(arguments);
                }
                gtag('js', new Date());`
            }</script>
            <script src="https://leadbooster-chat.pipedrive.com/assets/loader.js" async></script>
        </Helmet>

        <Router>
            <Navbar />
            <Routes>
                <Route exact path='/' element={<Trips />} />
                <Route exact path='/trip/selfdrive' element={<SelfDrive />}/>
                <Route path='/trip/:slug' element={<Trip />}/>
                <Route exact path='/thank-you-for-booking-with-us/:id' element={<ThankYou />} render={({ location }) => {
            window.gtag('config', 'GTM-TBPXHJL', {
              page_path: location.pathname + location.search,
            });
          }}/>
            </Routes>
            <Footer />
        </Router>
    </div>
  );
}

export default App;
