import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import axios from 'axios';
import * as CryptoJS from 'crypto-js'

const SECRET = 'ManGoRRRob'

function decrypt(cipherText){
    var reb64 = CryptoJS.enc.Hex.parse(cipherText);
    var bytes = reb64.toString(CryptoJS.enc.Base64);
    var decrypt = CryptoJS.AES.decrypt(bytes, SECRET);
    var plain = decrypt.toString(CryptoJS.enc.Utf8);
    return plain;
}

const id=window.location.href.split('/')[4];

export class About extends Component {
    state = {
        isLoaded: false,
        pax_name: "",
        bokunarcode:"",
        pax_email:"",
        pax_phone:"",
        tourname:"",
        v_dagur:"",
        pickuptime:"",
        pickupname:"",
        totalPassagers:"",
        startprice:"",
        totalFee:"",
        ispickup:"",
        validGiftcard:"",
        giftcard:"",
        discount:"",
        discounttotal:""
    }


    componentDidMount () {
        axios.post('https://api.airmango.is/receipt/'+id)
        .then(res => this.setState({
            pax: res.data.data.receipt[0].pax,
            pax_name: res.data.data.receipt[0].name,
            pax_email:res.data.data.receipt[0].email,
            pax_phone:res.data.data.receipt[0].phoneNumber,
            bokunarcode:res.data.data.receipt[0].bookingCode,
            tourname:res.data.data.receipt[0].title,
            v_dagur:res.data.data.receipt[0].dagur,
            totalPassagers:res.data.data.receipt[0].pax,
            totalFee:res.data.data.receipt[0].price,
            isLoaded: true
        }))
        .catch(err => console.log(err))
    }

    render() {
        const {bokunarcode,tourname,pax_email, isLoaded} = this.state;
        if (isLoaded) {
            return (
                <div className="wrapper thankyou">
                    <Helmet>
                        <title>Tours at buubble.com - Thank you for booking with us! </title>
                        <script data-cfasync="false" data-pagespeed-no-defer type="text/javascript">
                        {
                            `gtag('event', 'conversion', {
                                'send_to': 'AW-969173944/dEDJCJetroQBELjXkc4D',
                                'transaction_id': ''
                            });`
                        }</script>
                    </Helmet>
                    <div className="main">
                        <h1>Thank you for booking "{tourname}" with us!</h1>
                        <h3 className='ticket'>Your Booking Code: <strong>{bokunarcode}</strong></h3>
                        <p>An confirmation email with your ticket was sent to your email address <strong>{pax_email}</strong></p>
                        <p>If you require any further information or if there are any problems <a href="https://www.buubble.com/contact-us/" rel="noreferrer" target='_blank'>contact us here</a></p>
                        <p>Safe journey</p>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="wrapper">
                    <div className="main"></div>
                    <p className="hled">Hleð</p>
                </div>
            )
        }
    }

}

export default About