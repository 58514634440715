import React, { Component } from 'react'
import axios from 'axios';

import Itinerary from "../../src/components/Itinerary";
import BokunarvelView from "../../src/components/BokunarvelView";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-TBPXHJL'
}

TagManager.initialize(tagManagerArgs)

const slug=window.location.href.split('/')[4];

export class Trip extends Component {
    state = { trips: [], isLoaded: false }
    componentDidMount () {

        import('react-facebook-pixel')
            .then((x) => x.default)
            .then((ReactPixel) => {
                ReactPixel.init('726172684096484');
                ReactPixel.pageView();
        });
        
        axios.get('https://cms.airmango.is/wp-json/wp/v2/trips?slug='+slug)
        .then(res => this.setState({
            trips: res.data,
            isLoaded: true
        }))
        .catch(err => console.log(err))
    }

    render() {
        const {trips, isLoaded} = this.state;
        if (isLoaded) {
            const ferdin = trips[0];
            var titletofixed = ferdin.title.rendered;
            var fixedtitle = titletofixed.replace("&#8211;", '–').replace("&#038;", '&');
            return (
                <div className="airmango-tour content">
                    <div className="submenu">
                        <ul>
                            <li><a href="/"><strong>All </strong> private tours</a></li>
                        </ul>
                    </div>
            <link rel="stylesheet" type="text/css" charSet="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
            <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
            <div className="tripContainer" key={ ferdin.id }>
                <div className="myndasafn">
                    <div className="alpha"><figure style={{ backgroundImage: 'url('+ ferdin.acf.gallery.image_1 +')' }}></figure></div>
                    <div className="beta"><figure style={{ backgroundImage: 'url('+ ferdin.acf.gallery.image_2 +')' }}></figure></div>
                    <div className="gamma"><figure style={{ backgroundImage: 'url('+ ferdin.acf.gallery.image_3 +')' }}></figure><figure style={{ backgroundImage: 'url('+ ferdin.acf.gallery.image_4 +')' }}></figure></div>
                    <div className="delta"><figure style={{ backgroundImage: 'url('+ ferdin.acf.gallery.image_5 +')' }}></figure></div>
                </div>
                <div className="ferdin">
                    <h1 className="tripname">{fixedtitle}</h1>
                </div>
                <div className="ferdin w">
                    <div className="efnid">
                        <div className='tripinfo'>
                            <div dangerouslySetInnerHTML={{__html: ferdin.content.rendered}}></div>
                            <h2>What is included</h2>
                            <div className="inc-pack">
                                <i className="air_icon regular icon_accommodation"></i>
                                <h3 className="inc-pack-title">Accommodation</h3>
                                <p>{ferdin.acf.accommodation}</p>
                            </div><div className="inc-pack">
                                <i className="air_icon regular icon_transportation"></i>
                                <h3 className="inc-pack-title">Transportation</h3>
                                <p>{ferdin.acf.transportation}</p>
                            </div><div className="inc-pack">
                                <i className="air_icon regular icon_charging"></i>
                                <h3 className="inc-pack-title">Activities</h3>
                                <p>{ferdin.acf.activities}</p>
                            </div>
                        </div>
                    </div>
                    <div className='tripinfo'>
                        <h2>Your itinerary</h2>
                        <p className='tripinfo'>Enjoy unique experience</p>
                        {ferdin.acf.inineraries ? (
                            ferdin.acf.inineraries.map(post =>
                                <div className="itineraryitem item" key={post.ID}>
                                    <Itinerary itinerary={post.ID}/>
                                </div>
                            )
                        ) : (
                            <div>
                                <p>No itinerary</p>
                            </div>
                        )}
                    </div>
                    <h2>Your itinerary on a map</h2>
                    <div className='howtogetthere' dangerouslySetInnerHTML={{__html: ferdin.acf.map}}></div>
                    <div className="thinkstoknow"  dangerouslySetInnerHTML={{__html: ferdin.acf.things_to_know}}></div>
                </div>
                <div className="sidebar">
                <BokunarvelView bookid={ferdin.acf.tour_code} />
                </div>
            </div>
        </div>
            )
        } else {
            return (
                <div>
                     <div className="submenu">
                        <ul>
                            <li><a href="/"><strong>All </strong> private tours</a></li>
                        </ul>
                    </div>
                    <h3>Loading tour...</h3>
                </div>
            )
        }
    }
}

export default Trip