import React, { Component } from 'react'
import axios from 'axios';

export class Itinerary extends Component {
    state = { posts: [], isLoaded: false, popup: "" }

    componentDidMount () {
        axios.get('https://cms.airmango.is/wp-json/wp/v2/itinerary/'+this.props.itinerary,{
            headers : {
                'Content-Type' : 'application/json;charset=utf-8;'
            },
            crossdomain: true,
            params: {
                categories: this.props.itinerary
            }
        })
        .then(res => this.setState({ posts: res.data, isLoaded: true }))
        .catch(err => console.log(err))
    }
    
    doThis = () => {
        this.setState({ popup: "open" })
    }

    notdoThis = () => {
        this.setState({ popup: "" })
    }

    render() {

        const {posts, isLoaded, popup} = this.state;

        if (isLoaded) {
            return (
                <div className="itinerary">
                    <div className='gallery'>
                        <div className='mainimg'>
                            <figure style={{ backgroundImage: 'url('+ posts.acf.image +')' }} ></figure>
                            <h3 className='gamla'>Day {posts.acf.day}</h3>
                        </div><div className='xtraimg'>
                            <figure style={{ backgroundImage: 'url('+ posts.acf.image_2 +')' }} className="t"></figure>
                            <figure style={{ backgroundImage: 'url('+ posts.acf.image_3 +')' }} className="b"></figure>
                        </div>
                    </div>
                    <div className='itinerary_info'>
                        <h3>{fixtitle(posts.title.rendered)}</h3>
                        <h4>{posts.acf.subject}</h4>
                        <p>{posts.acf.short_description}</p>
                        <button onClick={this.doThis}>More info {popup}</button>
                    </div>
                    <div className={"more_info_day " + popup}>
                        <div className="wrapper">
                            <button className='close_me' onClick={this.notdoThis}>X</button>
                            <div className="scrollingmydayaway">
                            <span>Day {posts.acf.day}</span>
                                <h2>{fixtitle(posts.title.rendered)}</h2>
                                <h3 className='subject'>{posts.acf.subject}</h3>
                                <div className="itinerary_info_day" dangerouslySetInnerHTML={{__html: posts.content.rendered}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="itinerary">
                    <div className="myndband"></div>
                </div>
            )
        }
    }
}

function fixtitle(p) {
    var titletofixed = p;
    var fixedtitle = titletofixed.replace("&#8211;", '–').replace("&#038;", '&');
    return fixedtitle;
}

export default Itinerary;